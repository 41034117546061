import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@gasbuddy/react-components';
import client from './graphql/client';
import Router from '../common/router';

export default function renderApp() {
  const container = document.getElementById('root');
  const supportsHistory = 'pushState' in window.history;

  hydrateRoot(container, (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <BrowserRouter forceRefresh={!supportsHistory}>
          <ThemeProvider theme="next">
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ApolloProvider>
  ));
}
