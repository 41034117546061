const graphqlCacheConfig = {
  typePolicies: {
    ConfigMap: {
      keyFields: false,
      merge: true,
    },
    Station: {
      fields: {
        address: {
          merge: true,
        },
      },
    },
  },
};

export default graphqlCacheConfig;
