import { loadableReady } from '@loadable/component';
import renderWithHMR from './hot-loader';

if (window) {
  // https://webpack.js.org/guides/public-path/#on-the-fly
  // eslint-disable-next-line no-undef, camelcase
  __webpack_public_path__ = window.PublicPath;
}

const render = () => renderWithHMR();

loadableReady(() => {
  render();
});
