import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import fetch from 'node-fetch';
import graphqlCacheConfig from '../../common/graphqlCache';

const inBrowserWindow = typeof window !== 'undefined';

// eslint-disable-next-line no-underscore-dangle
const initialState = inBrowserWindow ? window.__APOLLO_STATE__ : {};
const gbcsrf = inBrowserWindow && window.gbcsrf;

const httpLink = new HttpLink({ uri: '/graphql', fetch });
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      gbcsrf,
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  credentials: 'include',
  cache: new InMemoryCache(graphqlCacheConfig).restore(initialState),
  link: concat(authMiddleware, httpLink),
});

export default client;
