import renderApp from './renderApp';

export default function shouldEnableHot() {
  if (process.env.NODE_ENV === 'development' && import.meta.webpackHot) {
    import.meta.webpackHot.accept('./renderApp', () => {
      // eslint-disable-next-line global-require
      const processUpdate = require('./renderApp').default;
      processUpdate();
    });
  }

  return renderApp();
}
